<template>
  <div>
		<el-card>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true"  class="demo-form-inline">
						<el-form-item label="微信昵称">
							<el-input v-model="searchText" placeholder="请输入微信昵称" clearable></el-input>
						</el-form-item>
						<el-form-item label="手机号">
							<el-input v-model="phoneText" placeholder="请输入手机号" clearable></el-input>
						</el-form-item>
						<el-form-item label="加入时间">
							<el-date-picker
					      v-model="datePicker"
					      type="daterange"
					      range-separator="至"
					      start-placeholder="开始日期"
					      end-placeholder="结束日期"
					      value-format="yyyy-MM-dd">
					    </el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<!-- 服务列表 -->
			<el-table :data="user_list" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="wx_id" label="ID"></el-table-column>
				<el-table-column prop="nickName" label="微信昵称"></el-table-column>
				<el-table-column label="微信头像">
					<template slot-scope="scope">
						<div>
							<el-avatar shape="square" size="large" :src="scope.row.avatarUrl"></el-avatar>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="phoneNumber" label="手机号"></el-table-column>
				<el-table-column prop="createtime" label="加入时间"></el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		data() {
			return {
				total: 0,
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				searchText: "",
				phoneText: "",
				datePicker: "",
				user_list: [],
			};
		},
		components: {
			Pagination
		},
		created() {
			if (!this.$store.state.userInfo) {
	        // 防止未登录
	        this.$router.push({path: '/login'})
	    }else{
					this.getUserList();
			}
		},
		methods: {
			getUserList() {
				let that = this;
				var url = 'custom/vip_list';
				let params = {
					user_name: this.searchText,
					phone: this.phoneText,
					date_time: this.datePicker,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.user_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			search(){
				this.getUserList();
			},
			// 重置
			reset() {
				this.searchText = "";
				this.phoneText = "";
				this.datePicker = "";
				this.getUserList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getUserList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getUserList();
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "./Vip_list.scss";
</style>